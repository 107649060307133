import React from 'react';
import Icon from '@ant-design/icons';
import { observer } from 'mobx-react';
import UserItemLabel from './UserItemLabel';
import { useStore } from '../../../../store/rootStore';
import blankProfilePicture from '../../../../assets/avatars/blankProfilePicture.png';
import { ReactComponent as SingleCheck } from '../../../../assets/icons/chatStatuses/singleCheck.svg';
import './UserItemStyles.scss';
const UserItem = ({ user, isActive, setUser }) => {
    const { departmentsStore } = useStore();
    const userDepartments = departmentsStore.departments.filter((department) => { var _a; return (_a = department.agents) === null || _a === void 0 ? void 0 : _a.includes(user._id); });
    return (React.createElement("div", { className: isActive ? 'conversation-list-item user-item__active' : 'conversation-list-item', onClick: () => setUser(user._id) },
        React.createElement("img", { className: "conversation-photo", src: blankProfilePicture }),
        React.createElement("div", { className: 'conversation-info' },
            React.createElement("div", { className: "conversation-title-wrapper" },
                React.createElement("h1", { className: "conversation-title" }, user.displayName || user.email),
                React.createElement("div", { className: "conversation-message user-item__department" }, userDepartments.map((department) => (React.createElement(UserItemLabel, { key: department._id, department: department }))))),
            isActive && (React.createElement("div", { className: "conversation-status-block user-item__img-container" },
                React.createElement(Icon, { component: SingleCheck, className: 'user-item__img' }))))));
};
export default observer(UserItem);
