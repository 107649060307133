import React from 'react';
import { Trans } from 'react-i18next';
import { TEMPLATE_SORT } from '../../../constants';
import classNames from 'classnames';
import { MyInput, MySelect } from '../../Base/Form';
import { Radio } from 'antd';
import { getDirection } from '../../../helpers';
import clearInputIcon from '../../../assets/messageStatuses/Cross.png';
import DepartmentsSelector from '../../Chat/common/DepartmentsSelector';
import styles from './Filters.module.scss';
const Filters = ({ sort, onSortChanged, department, onDepartmentChanged, searchValue, onSearchChanged, disabled, isLessThanDesktop, i18n, }) => {
    return (React.createElement("div", { className: styles.filterContainer },
        React.createElement("div", { className: styles.filtersWrapper },
            React.createElement("div", { className: styles.templateSearch },
                React.createElement(MyInput, { value: searchValue, onChangeHandler: onSearchChanged, className: styles.templateSearchInput, allowClear: {
                        clearIcon: (React.createElement("img", { className: classNames(styles.cleanSearchInput, {
                                [styles.rtl]: getDirection() === 'rtl',
                            }), src: clearInputIcon })),
                    }, type: "search", size: "middle", placeholder: i18n.t('searchTemplates'), bordered: false, autoFocus: true })),
            React.createElement(DepartmentsSelector, { onChange: (value) => value === 'allDepartments' ? onDepartmentChanged(null) : onDepartmentChanged(value), value: department || 'allDepartments', style: styles.depFilter, disabled: disabled, size: "middle" }),
            React.createElement("div", { className: styles.genFilterContainer }, !isLessThanDesktop ? (React.createElement(React.Fragment, null,
                React.createElement("span", null,
                    React.createElement(Trans, { i18nKey: "sort" })),
                React.createElement(Radio.Group, { value: sort, onChange: (e) => onSortChanged(e.target.value), disabled: disabled, buttonStyle: "solid" },
                    React.createElement(Radio.Button, { value: TEMPLATE_SORT.NAME },
                        React.createElement(Trans, { i18nKey: "nameAZ" })),
                    React.createElement(Radio.Button, { value: TEMPLATE_SORT.LAST_USED },
                        React.createElement(Trans, { i18nKey: "lastUsed" })),
                    React.createElement(Radio.Button, { value: TEMPLATE_SORT.DATE_CREATED },
                        React.createElement(Trans, { i18nKey: "dateCreated" }))))) : (React.createElement(MySelect, { value: sort, onChange: onSortChanged, className: classNames(styles.genFilter, {
                    [styles.rtl]: getDirection() === 'rtl',
                }), placeholder: React.createElement(Trans, { i18nKey: "sort" }), disabled: disabled, size: "middle", options: [
                    {
                        label: React.createElement(Trans, { i18nKey: "nameAZ" }),
                        value: TEMPLATE_SORT.NAME,
                    },
                    {
                        label: React.createElement(Trans, { i18nKey: "lastUsed" }),
                        value: TEMPLATE_SORT.LAST_USED,
                    },
                    {
                        label: React.createElement(Trans, { i18nKey: "dateCreated" }),
                        value: TEMPLATE_SORT.DATE_CREATED,
                    },
                ] }))))));
};
export default Filters;
