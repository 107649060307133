import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import ActiveIcon from '../../../Chat/ActiveIcon';
import { Typography, Badge, Divider, Tabs, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Button from 'antd/es/button';
import { ReactComponent as QuickRepliesCross } from '../../../../assets/messageStatuses/closeCross.svg';
import UserItem from '../../../Base/Items/UserItem/UserItem';
import DepartmentItem from '../../../Base/Items/DepartmentItem/DepartmentItem';
import { Trans, useTranslation } from 'react-i18next';
import { useStore } from '../../../../store/rootStore';
import classNames from 'classnames';
import { getDirection } from '../../../../helpers';
import Filters from './Filters/Filters';
import './AssignToModal.scss';
const { Text } = Typography;
const { TabPane } = Tabs;
const AssignToModal = ({ toggleModal, isAssignModalVisible, dashboardChat, assignFunction }) => {
    const { agentsStore, activeChatStore, departmentsStore } = useStore();
    const { t, i18n } = useTranslation();
    const [activeUserId, setActiveUserId] = useState('');
    const [activeDepartmentId, setActiveDepartmentId] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [tabKey, setTabKey] = useState('agents');
    const [searchValue, setSearchValue] = useState('');
    const searchValueLC = searchValue.toLowerCase();
    function reset() {
        setActiveUserId('');
        setActiveDepartmentId('');
        setSearchValue('');
    }
    function onTabChange(key) {
        setTabKey(key);
        reset();
    }
    function setUser(uid) {
        setActiveUserId(uid);
    }
    function setDepartment(id) {
        setActiveDepartmentId(id);
    }
    function setAssignee() {
        setLoading(true);
        const user = agentsStore.usersToAssignee.find((user) => user.uid === activeUserId);
        let chatToAssign;
        if (dashboardChat) {
            chatToAssign = dashboardChat;
        }
        else if (activeChatStore.chat) {
            chatToAssign = activeChatStore.chat._id;
        }
        assignFunction(chatToAssign, user ? user._id : undefined, activeDepartmentId)
            .then(() => {
            toggleModal(false);
            reset();
        })
            .catch((error) => console.log('Error: assigning chat to agent', error))
            .finally(() => {
            setLoading(false);
        });
    }
    const sortAgents = (agents) => {
        return agents.sort((a, b) => {
            if (a.displayName < b.displayName) {
                return -1;
            }
            if (a.displayName > b.displayName) {
                return 1;
            }
            return 0;
        });
    };
    const filterItems = (field) => (item) => {
        if (searchValue) {
            return item[field].toLowerCase().includes(searchValueLC);
        }
        return true;
    };
    useEffect(() => {
        if (!isAssignModalVisible) {
            reset();
        }
    }, [isAssignModalVisible]);
    return (React.createElement(Modal, { open: isAssignModalVisible, footer: null, width: 800, destroyOnClose: true, onCancel: () => toggleModal(false), centered: true, bodyStyle: { padding: '0' }, closable: false },
        React.createElement("div", { className: classNames('assign-to-modal-wrapper', {
                'rtl': getDirection() === 'rtl',
            }) },
            React.createElement("div", { className: 'assign-to-modal-title' },
                React.createElement(Text, { className: 'assign-to-modal-title__text' },
                    React.createElement(Trans, { i18nKey: "assignChat" })),
                React.createElement("div", { className: "assign-to-modal-close " },
                    React.createElement(ActiveIcon, { component: QuickRepliesCross, action: () => {
                            toggleModal(false);
                        } }))),
            React.createElement("div", { className: 'assign-to-modal-body' },
                React.createElement(Tabs, { activeKey: tabKey, onChange: onTabChange, className: 'assign-to-modal-body__tabs' },
                    React.createElement(TabPane, { tab: t('agents'), key: "agents" },
                        React.createElement("div", { className: 'assign-to-modal-body__content' }, React.createElement(React.Fragment, null,
                            React.createElement(Text, { className: 'assign-to-modal-body__title' },
                                React.createElement(Badge, { color: "#0FB878", text: `${agentsStore.onlineUsersToAssignee.length} ${t('onlineAgents')}` })),
                            agentsStore.onlineUsersToAssignee.length
                                ? sortAgents(agentsStore.onlineUsersToAssignee)
                                    .filter(filterItems('displayName'))
                                    .map((user) => (React.createElement("div", { key: user.uid },
                                    React.createElement(UserItem, { user: user, isActive: activeUserId === user.uid, setUser: setUser }))))
                                : '',
                            React.createElement(Divider, null),
                            React.createElement(Text, { className: 'assign-to-modal-body__title' },
                                React.createElement(Badge, { color: "#B7B7B7", text: `${agentsStore.offlineUsersToAssignee.length} ${t('offlineAgents')}` })),
                            agentsStore.offlineUsersToAssignee.length
                                ? sortAgents(agentsStore.offlineUsersToAssignee)
                                    .filter(filterItems('displayName'))
                                    .map((user) => (React.createElement("div", { key: user.uid },
                                    React.createElement(UserItem, { user: user, isActive: activeUserId === user.uid, setUser: setUser }))))
                                : ''))),
                    departmentsStore.departments && departmentsStore.departments.length > 0 ? (React.createElement(TabPane, { tab: t('departments'), key: "departments" },
                        React.createElement("div", { className: 'assign-to-modal-body__content' }, React.createElement(React.Fragment, null, departmentsStore.departments.length
                            ? departmentsStore.departments
                                .filter(filterItems('name'))
                                .map((department) => (React.createElement("div", { key: department._id },
                                React.createElement(DepartmentItem, { department: department, isActive: activeDepartmentId === department._id, setDepartment: setDepartment }))))
                            : '')))) : null),
                React.createElement(Filters, { searchValue: searchValue, onSearchChanged: setSearchValue, placeholder: tabKey === 'agents' ? i18n.t('searchAgents') : i18n.t('searchDepartments') })),
            React.createElement("div", { className: 'assign-to-modal-footer' },
                React.createElement(Button, { className: "big-button white-button assign-to-modal-footer__button", onClick: () => {
                        toggleModal(false);
                    } },
                    React.createElement(Trans, { i18nKey: "cancel" })),
                React.createElement(Button, { disabled: !activeUserId && !activeDepartmentId, type: "primary", className: "assign-to-modal-footer__button big-button assign-to-button", onClick: () => setAssignee() }, isLoading ? React.createElement(LoadingOutlined, null) : React.createElement(Trans, { i18nKey: "assign" }))))));
};
export default observer(AssignToModal);
