import React from "react";
import { observer } from "mobx-react";
import { Button, Modal } from "antd";
import { Trans } from "react-i18next";
import classNames from "classnames";
import blankProfilePicture from "../../../../assets/avatars/blankProfilePicture.png";
import s from "./SubscribeModal.module.scss";
import ChatIdInfo from "../../ChatIdInfo";
import ChatTitle from "../../ChatTitle";
import { getDirection } from "../../../../helpers";
const SubscribeModal = ({ visible, onCancel, onOk, activeChat }) => {
    if (!activeChat)
        return null;
    const { title, image } = activeChat, unsubscribed = !!activeChat.unsubscribed || !!activeChat.blockTemplates;
    return (React.createElement(Modal, { open: visible, onCancel: onCancel, footer: false, className: s.modalContainer, width: 768, closable: false, centered: true },
        React.createElement("div", { className: s.container },
            React.createElement("img", { src: image || blankProfilePicture, className: s.avatar }),
            React.createElement("div", { className: s.title },
                React.createElement(ChatTitle, { chat: activeChat, showId: "none" })),
            React.createElement("div", { className: s.phone },
                React.createElement(ChatIdInfo, { chat: activeChat })),
            React.createElement("div", { className: classNames(s.message, { [s.rtl]: getDirection() === 'rtl' }) },
                React.createElement(Trans, { i18nKey: unsubscribed ? "sureToSubscribeTM" : "sureToUnsubscribeTM", values: { name: title } })),
            React.createElement("div", { className: s.actionsContainer },
                React.createElement(Button, { type: unsubscribed ? 'default' : 'primary', onClick: onCancel, className: classNames(s.actionButton, s.cancelButton) },
                    React.createElement(Trans, { i18nKey: unsubscribed ? 'cancel' : 'keepSubscribed' })),
                React.createElement(Button, { type: "primary", onClick: onOk, className: s.actionButton, danger: !unsubscribed },
                    React.createElement(Trans, { i18nKey: unsubscribed ? 'resubscribe' : 'unsubscribe' }))))));
};
export default observer(SubscribeModal);
